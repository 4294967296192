import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useToasts } from "react-toast-notifications"
import truncate from "lodash.truncate"
import Select from "react-select"
import moment from "moment"
import get from "lodash.get"

// Components
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
  Button,
  Text,
  Grid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from "@chakra-ui/react"
import { ThreeDotsVertical } from "@styled-icons/bootstrap/ThreeDotsVertical"
import { Plus } from "@styled-icons/fa-solid"
import Search from "./search"

// Icons
import ToothIcon from "./tooth"
import WarningIcon from "./warningIcon"

import { CheckIcon } from "../../../../atoms/Common"

// Utils
import TeethArray from "./teeth"
import { honeybadger } from "../../../../../utils/App"

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderColor: "#E2E8F0", // Theme.color.iceBlue,
    marginTop: "5px",
    marginBottom: "2px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1
    const transition = "opacity 300ms"
    return { ...provided, opacity, transition }
  },
  menuList: (base) => ({ ...base, height: "242px" }),
}

const Odontogram = ({ firebase, contact, user, isReadOnly }) => {
  const { addToast } = useToasts()
  const { t, i18n } = useTranslation()
  const lang = get(i18n, "language", "es").split("-")

  const [toothToUpdate, setToothToUpdate] = useState({})
  const [isAllToothSelected, setIsAllToothSelected] = useState({
    tooth_number: null,
    isSelected: false,
    procedures: [],
  })
  const [initialOdontogramData, setInitialOdontogramData] = useState([])
  const [odontogramData, setOdontogramData] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [dentalProcedures, setDentalProcedures] = useState([])
  const [dentalObservations, setDentalObservations] = useState([])
  const [initialTeethData, setInitialTeethData] = useState([])
  const [teethData, setTeethData] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [isFirstMount, setFirstMount] = useState(true)
  const [isInitialTab, setIsInitial] = useState(true)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    isOpen: isWarningModalOpen,
    onOpen: onOpenWarningModal,
    onClose: onCloseWarningModal,
  } = useDisclosure()

  const {
    isOpen: isPeriodontalNotesOpen,
    onOpen: onOpenPeriodontalNotes,
    onClose: onClosePeriodontalNotes,
  } = useDisclosure()

  const getOdontogramData = async () => {
    setTeethData({})
    setInitialTeethData({})
    const odontogramRecords = await firebase.getClientOdontogram({
      clientEmail: contact?.email,
    })

    if (odontogramRecords) {
      const records = odontogramRecords.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }))

      const getInitialData = records.filter((record) => record?.isInitial)
      const getEvolutivoData = records.filter((record) => !record?.isInitial)
      setInitialOdontogramData(getInitialData)
      setOdontogramData(getEvolutivoData)

      if (getEvolutivoData && getEvolutivoData?.length > 0 && isFirstMount) {
        setActiveTab(1)
        setFirstMount(false)
      }

      // this fill the Tooth part when has some data and shows a warning if has pending items
      records?.forEach((item) => {
        if (item?.historical) {
          item.historical?.forEach((procedure) => {
            if (item?.isInitial) {
              setInitialTeethData((prevState) => ({
                ...prevState,
                [item.tooth_number]: {
                  tooth_number: item.tooth_number,
                  hasPendingProcedures: !procedure?.isDone,
                  vestibularHasProcedures:
                    prevState?.[item.tooth_number]?.vestibularHasProcedures ||
                    procedure.name === "vestibular",
                  distalHasProcedures:
                    prevState?.[item.tooth_number]?.distalHasProcedures ||
                    procedure.name === "distal",
                  palatina_ingualHasProcedures:
                    prevState?.[item.tooth_number]
                      ?.palatina_ingualHasProcedures ||
                    procedure.name === "palatina_ingual",
                  oclusal_incisalHasProcedures:
                    prevState?.[item.tooth_number]
                      ?.oclusal_incisalHasProcedures ||
                    procedure.name === "oclusal_incisal",
                  mesialHasProcedures:
                    prevState?.[item.tooth_number]?.mesialHasProcedures ||
                    procedure.name === "mesial",
                },
              }))
            } else {
              setTeethData((prevState) => ({
                ...prevState,
                [item.tooth_number]: {
                  tooth_number: item.tooth_number,
                  hasPendingProcedures: !procedure?.isDone,
                  vestibularHasProcedures:
                    prevState?.[item.tooth_number]?.vestibularHasProcedures ||
                    procedure.name === "vestibular",
                  distalHasProcedures:
                    prevState?.[item.tooth_number]?.distalHasProcedures ||
                    procedure.name === "distal",
                  palatina_ingualHasProcedures:
                    prevState?.[item.tooth_number]
                      ?.palatina_ingualHasProcedures ||
                    procedure.name === "palatina_ingual",
                  oclusal_incisalHasProcedures:
                    prevState?.[item.tooth_number]
                      ?.oclusal_incisalHasProcedures ||
                    procedure.name === "oclusal_incisal",
                  mesialHasProcedures:
                    prevState?.[item.tooth_number]?.mesialHasProcedures ||
                    procedure.name === "mesial",
                },
              }))
            }
          })
        }
      })
    }
  }

  const getDentalProcedures = async () => {
    const dentalProceduresApi = await firebase.getFormOptions(
      "dentalProcedures"
    )
    const dentalProceduresData = dentalProceduresApi.data()
    setDentalProcedures(
      get(dentalProceduresData, `options.${lang?.[0]}`, []).map(
        (procedure) => ({
          ...procedure,
        })
      )
    )
  }

  const getDentalObservations = async () => {
    const dentalObservationsApi = await firebase.getFormOptions(
      "dentalObservations"
    )
    const dentalObservationsData = dentalObservationsApi.data()
    setDentalObservations(
      get(dentalObservationsData, `options.${lang?.[0]}`, []).map(
        (observation) => ({
          ...observation,
          label: observation?.name,
          value: observation?.value,
        })
      )
    )
  }

  useEffect(() => {
    if (firebase) {
      getDentalProcedures()
      getDentalObservations()
      getOdontogramData()
    }
  }, [firebase, i18n?.language])

  const onClickTooth = (id, name, isInitial) => {
    setToothToUpdate({
      id: Date.now().toString(36) + Math.random().toString(36).substr(2),
      tooth_number: id,
      name,
      isDone: false,
      createdAt: Date.now(),
      createdBy: {
        email: user.isImpersonating ? user.impersonatingEmail : user.email,
        name: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
        profession: user?.medicProfile?.profession,
      },
    })

    setIsInitial(isInitial)
    if (!isReadOnly) {
      onOpen()
    }
  }

  const onClickAllTooth = (id) => {
    setIsAllToothSelected((prevState) => ({
      ...prevState,
      tooth_number: id,
      isSelected: true,
    }))
  }

  const handleCreate = async (isPeriodontologicalNote = false) => {
    try {
      const preparedData = {
        tooth_number: toothToUpdate?.tooth_number,
        historical: [toothToUpdate],
        createdAt: Date.now(),
        isInitial: isInitialTab,
      }

      const getItem = isInitialTab
        ? initialOdontogramData?.find(
            (data) => data?.tooth_number === toothToUpdate?.tooth_number
          )
        : odontogramData?.find(
            (data) => data?.tooth_number === toothToUpdate?.tooth_number
          )

      if (getItem?.id) {
        const updatedData = {
          ...getItem,
          historical: [...getItem?.historical, toothToUpdate],
          updatedAt: Date.now(),
        }

        await firebase.updateOdontogramRecord({
          clientEmail: contact?.email,
          recordId: getItem?.id,
          record: updatedData,
        })
      } else {
        await firebase.createOdontogramRecord({
          clientEmail: contact?.email,
          record: preparedData,
        })
      }

      addToast(t("web_client.saved_successfully"), {
        appearance: "success",
        placement: "top-right",
      })

      setToothToUpdate({})
      setTimeout(async () => {
        await getOdontogramData()
      }, 300)

      if (isPeriodontologicalNote) {
        onClosePeriodontalNotes()
      } else {
        onClose()
      }
    } catch (error) {
      onClose()
      onClosePeriodontalNotes()
      honeybadger.notify("Odotogram / handleCreate Func", {
        message: "Error on create item",
        action: "submit",
        context: {
          error,
        },
      })
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleEdit = async (isPeriodontologicalNote = false) => {
    try {
      const getItem = isInitialTab
        ? initialOdontogramData?.find(
            (data) => data?.tooth_number === toothToUpdate?.tooth_number
          )
        : odontogramData?.find(
            (data) => data?.tooth_number === toothToUpdate?.tooth_number
          )

      if (getItem?.id) {
        const filterUpdatedProcedure = getItem?.historical?.filter(
          (procedure) => procedure?.id !== toothToUpdate?.id
        )
        const dataUpdated = {
          updatedAt: Date.now(),
          historical: [...filterUpdatedProcedure, toothToUpdate],
        }

        await firebase.updateOdontogramRecord({
          clientEmail: contact?.email,
          recordId: getItem?.id,
          record: dataUpdated,
        })

        addToast(t("web_client.saved_successfully"), {
          appearance: "success",
          placement: "top-right",
        })
      } else {
        addToast(t("unexpected_error"), { appearance: "error" })
      }

      if (isEditing) {
        setIsEditing(false)
      }

      setToothToUpdate({})
      setTimeout(async () => {
        await getOdontogramData()
      }, 300)

      if (isPeriodontologicalNote) {
        onClosePeriodontalNotes()
      } else {
        onClose()
      }
    } catch (error) {
      onClose()
      onClosePeriodontalNotes()
      honeybadger.notify("Odotogram / handleEdit Func", {
        message: "Error on edit item",
        action: "submit",
        context: {
          error,
        },
      })
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleRemove = async (toothToEdit) => {
    try {
      const getItem = isInitialTab
        ? initialOdontogramData?.find(
            (data) => data?.tooth_number === toothToEdit?.tooth_number
          )
        : odontogramData?.find(
            (data) => data?.tooth_number === toothToEdit?.tooth_number
          )

      if (getItem?.id) {
        const filterUpdatedProcedure = getItem?.historical?.filter(
          (procedure) => procedure?.id !== toothToEdit?.id
        )
        const dataUpdated = {
          updatedAt: Date.now(),
          historical: [...filterUpdatedProcedure],
        }

        await firebase.updateOdontogramRecord({
          clientEmail: contact?.email,
          recordId: getItem?.id,
          record: dataUpdated,
        })

        addToast(t("web_client.saved_successfully"), {
          appearance: "success",
          placement: "top-right",
        })
      } else {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
      setTimeout(async () => {
        await getOdontogramData()
      }, 300)
    } catch (error) {
      honeybadger.notify("Odotogram / handleRemove Func", {
        message: "Error on remove item",
        action: "submit",
        context: {
          error,
        },
      })
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleMarkProcedureDone = async (procedure, isInitial) => {
    try {
      const getItem = isInitial
        ? initialOdontogramData?.find(
            (data) => data?.tooth_number === procedure?.tooth_number
          )
        : odontogramData?.find(
            (data) => data?.tooth_number === procedure?.tooth_number
          )

      if (getItem?.id) {
        const updatedItems = getItem?.historical?.map((tooth) =>
          tooth?.id === procedure?.id
            ? { ...tooth, isDone: !procedure?.isDone }
            : tooth
        )
        const data = {
          updatedAt: Date.now(),
          historical: updatedItems,
        }
        await firebase.updateOdontogramRecord({
          clientEmail: contact?.email,
          recordId: getItem?.id,
          record: data,
        })
      } else {
        addToast(t("unexpected_error"), { appearance: "error" })
      }
      setTimeout(async () => {
        await getOdontogramData()
      }, 300)
    } catch (error) {
      honeybadger.notify("Odotogram / handleMarkProcedureDone Func", {
        message: "Error on mark done an item",
        action: "submit",
        context: {
          error,
        },
      })
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const handleOpenPeriodontalNotes = (editingInProgress) => {
    if (!editingInProgress) {
      setToothToUpdate(null)
    }
    onOpenPeriodontalNotes()
  }

  const onEditProcedure = (toothToEdit, isInitial, isPeriodontologicalNote) => {
    setIsEditing(true)
    setToothToUpdate(toothToEdit)
    setIsInitial(isInitial)

    if (isPeriodontologicalNote) {
      handleOpenPeriodontalNotes(true)
    } else {
      onOpen()
    }
  }

  const getSelectedToothData = (data, toothNumber) =>
    data?.find((tooth) => tooth?.tooth_number === toothNumber)

  const disableInitialActions = odontogramData?.length > 0
  return (
    <>
      <Flex
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height="calc(100vh - 240px)"
        width="100%"
        overflow="auto"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"
        borderRadius="16px"
        padding="20px"
      >
        <Tabs
          width="100%"
          variant="soft-rounded"
          colorScheme="green"
          index={activeTab}
        >
          <TabList
            backgroundColor="rgba(0, 0, 0, 0.04)"
            borderRadius="8px"
            padding="5px"
          >
            <Tab
              onClick={() => {
                setIsAllToothSelected({})
                setActiveTab(0)
              }}
              _focus={{
                boxShadow: "none",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              width="50%"
              color="#000000"
              cursor="pointer"
            >
              {t("initial")}
            </Tab>
            <Tab
              onClick={() => {
                setIsAllToothSelected({})
                setActiveTab(1)
                if (!odontogramData?.length) {
                  onOpenWarningModal()
                }
              }}
              _focus={{
                boxShadow: "none",
                color: "#000000",
              }}
              _selected={{
                boxShadow: "none",
                background: "white !important",
                color: "#000000",
              }}
              color="#000000"
              width="50%"
              cursor="pointer"
            >
              {t("evolutional")}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="center"
                mt="22px"
              >
                <Flex
                  flexDirection="column"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid templateColumns="repeat(16, 1fr)" gap={6} width="900px">
                    {TeethArray?.map((tooth) => (
                      <Flex key={tooth?.tooth_number} flexDirection="row">
                        <Flex
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="7px"
                          cursor="pointer"
                          border={
                            tooth?.tooth_number ===
                              isAllToothSelected?.tooth_number &&
                            isAllToothSelected?.isSelected
                              ? "1px solid #B794F4"
                              : "none"
                          }
                          background={
                            tooth?.tooth_number ===
                              isAllToothSelected?.tooth_number &&
                            isAllToothSelected?.isSelected
                              ? "#E9D8FD"
                              : "transparent"
                          }
                          onClick={() => {
                            onClickAllTooth(tooth?.tooth_number)
                            setIsInitial(true)
                          }}
                        >
                          <Text
                            color="#000"
                            fontSize="12px"
                            fontWeight="700"
                            mb="5px"
                          >
                            {tooth?.tooth_number}
                          </Text>
                          <ToothIcon
                            isInitial
                            id={tooth?.tooth_number}
                            onClick={onClickTooth}
                            teethData={initialTeethData}
                            isDisabled={disableInitialActions || isReadOnly}
                          />
                        </Flex>
                        {initialTeethData?.[tooth?.tooth_number]
                          ?.hasPendingProcedures && <WarningIcon />}
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
                <Box
                  mt="30px"
                  borderTop="1px solid rgba(0, 0, 0, 0.16)"
                  width="100%"
                />
                {getSelectedToothData(
                  initialOdontogramData,
                  isAllToothSelected?.tooth_number
                )?.tooth_number && isAllToothSelected?.isSelected ? (
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      mt="22px"
                      pr="20px"
                    >
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Text
                          color="#000"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mb="5px"
                        >
                          {`# ${
                            getSelectedToothData(
                              initialOdontogramData,
                              isAllToothSelected?.tooth_number
                            )?.tooth_number
                          } ${t("web_client.add_diagnosis")}`}
                        </Text>
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mb="5px"
                          ml="5px"
                        >
                          {t("web_client.add_diagnosis_two")}
                        </Text>
                      </Flex>

                      <Flex flexDirection="row" alignItems="center">
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mr="20px"
                        >
                          {t("add_periodontological_note")}
                        </Text>
                        <Button
                          size="sm"
                          variant="ghost"
                          backgroundColor="rgba(0, 0, 0, 0.06)"
                          _focus={{ outline: "none" }}
                          _hover={{ background: "#9F7AEA" }}
                          onClick={handleOpenPeriodontalNotes}
                          isDisabled={disableInitialActions || isReadOnly}
                        >
                          <Plus size="15px" color="#000000" />
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex
                      width="100%"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      mt="20px"
                    >
                      {getSelectedToothData(
                        initialOdontogramData,
                        isAllToothSelected?.tooth_number
                      )?.historical?.map((toothData, index) => (
                        <Flex
                          key={`${toothData?.name}-${index}`}
                          mb="5"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="80px"
                          >
                            <Text
                              color="#000"
                              fontSize="18px"
                              fontWeight="400"
                              lineHeight="27px"
                              textTransform="capitalize"
                              textAlign="left"
                            >
                              {toothData?.periodontologicalNote
                                ? t("add_medical_note")
                                : dentalObservations.find(
                                    (observation) =>
                                      observation?.value ===
                                      toothData?.observation
                                  )?.label || "No data"}
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="100px"
                          >
                            <Text
                              color="rgba(0, 0, 0, 0.36)"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="21px"
                              textAlign="left"
                            >
                              {toothData?.periodontologicalNote
                                ? t("add_medical_note")
                                : toothData?.name?.replace("_", " ") ||
                                  "No data"}
                            </Text>
                          </Box>

                          <Tooltip
                            label={
                              toothData?.observationText ||
                              toothData?.periodontologicalNote ||
                              "No data"
                            }
                          >
                            <Box width="300px">
                              <Text
                                color="#000"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="21px"
                                textAlign="left"
                              >
                                {truncate(
                                  toothData?.observationText ||
                                    toothData?.periodontologicalNote ||
                                    "No data",
                                  {
                                    length: 65,
                                  }
                                )}
                              </Text>
                            </Box>
                          </Tooltip>

                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="200px"
                          >
                            <Text
                              color="rgba(0, 0, 0, 0.36)"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="21px"
                              textTransform="Capitalize"
                              textAlign="left"
                            >
                              {toothData?.createdBy?.name || "No data"}
                            </Text>
                          </Box>

                          <Text
                            color="rgba(0, 0, 0, 0.36)"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="21px"
                          >
                            {moment(toothData?.createdAt)
                              .format("DD-MM-YYYY")
                              .toUpperCase()}
                          </Text>

                          <Tooltip
                            label={toothData?.procedures?.map(
                              (procedure, procedureIndex) =>
                                toothData?.procedures?.length ===
                                procedureIndex + 1
                                  ? `${procedure?.name}. `
                                  : `${procedure?.name}, `
                            )}
                          >
                            <Flex
                              background="#FAF5FF"
                              width="120px"
                              height="30px"
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="5px"
                            >
                              <Text
                                color="#9F7AEA"
                                fontSize="13px"
                                fontWeight="400"
                                lineHeight="24px"
                              >
                                {toothData?.periodontologicalNote
                                  ? t("add_medical_note")
                                  : truncate(
                                      toothData?.procedures?.[0]?.name ||
                                        "No data",
                                      {
                                        length: 18,
                                      }
                                    )}
                              </Text>
                            </Flex>
                          </Tooltip>

                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="24px"
                            height="24px"
                            background={toothData?.isDone ? "#68D391" : "#000"}
                            opacity={toothData?.isDone ? "1" : "0.3"}
                            borderRadius="100%"
                            cursor={
                              disableInitialActions ||
                              toothData?.isDone ||
                              isReadOnly
                                ? "not-allowed"
                                : "pointer"
                            }
                            onClick={() =>
                              disableInitialActions ||
                              toothData?.isDone ||
                              isReadOnly
                                ? null
                                : handleMarkProcedureDone(toothData, true)
                            }
                          >
                            <CheckIcon fillColor="#FFF" />
                          </Box>

                          <Box>
                            <Menu>
                              <MenuButton
                                isDisabled={
                                  disableInitialActions ||
                                  toothData?.isDone ||
                                  isReadOnly
                                }
                                as={Button}
                                rightIcon={
                                  <ThreeDotsVertical
                                    color="#000000"
                                    size={24}
                                  />
                                }
                              />
                              <MenuList background="white">
                                {!toothData?.isDone && (
                                  <MenuItem
                                    minH="40px"
                                    onClick={() =>
                                      handleMarkProcedureDone(toothData, true)
                                    }
                                  >
                                    <span>{t("complete")}</span>
                                  </MenuItem>
                                )}
                                <MenuItem
                                  minH="48px"
                                  onClick={() =>
                                    onEditProcedure(
                                      toothData,
                                      true,
                                      !!toothData?.periodontologicalNote
                                    )
                                  }
                                >
                                  <span>{t("edit")}</span>
                                </MenuItem>
                                <MenuItem
                                  minH="40px"
                                  onClick={() => handleRemove(toothData, true)}
                                >
                                  <span>{t("web_client.delete")}</span>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Box>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    mt="10px"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Text
                      mt="22px"
                      color="rgba(0, 0, 0, 0.36)"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="19.2px"
                      mb="5px"
                    >
                      {t("odontogram_title")}
                    </Text>

                    {isAllToothSelected?.tooth_number && (
                      <Flex flexDirection="row" alignItems="center">
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mr="20px"
                        >
                          {t("add_periodontological_note")}
                        </Text>
                        <Button
                          size="sm"
                          variant="ghost"
                          backgroundColor="rgba(0, 0, 0, 0.06)"
                          _focus={{ outline: "none" }}
                          _hover={{ background: "#9F7AEA" }}
                          onClick={handleOpenPeriodontalNotes}
                          isDisabled={disableInitialActions || isReadOnly}
                        >
                          <Plus size="15px" color="#000000" />
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            </TabPanel>

            {/* Evolutivo empieza aca */}
            <TabPanel>
              <Flex
                flexDirection="column"
                width="100%"
                justifyContent="center"
                mt="22px"
              >
                <Flex
                  flexDirection="column"
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid templateColumns="repeat(16, 1fr)" gap={6} width="900px">
                    {TeethArray?.map((tooth) => (
                      <Flex key={tooth?.tooth_number} flexDirection="row">
                        <Flex
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="7px"
                          cursor="pointer"
                          border={
                            tooth?.tooth_number ===
                              isAllToothSelected?.tooth_number &&
                            isAllToothSelected?.isSelected
                              ? "1px solid #B794F4"
                              : "none"
                          }
                          background={
                            tooth?.tooth_number ===
                              isAllToothSelected?.tooth_number &&
                            isAllToothSelected?.isSelected
                              ? "#E9D8FD"
                              : "transparent"
                          }
                          onClick={() => {
                            onClickAllTooth(tooth?.tooth_number)
                            setIsInitial(false)
                          }}
                        >
                          <Text
                            color="#000"
                            fontSize="12px"
                            fontWeight="700"
                            mb="5px"
                          >
                            {tooth?.tooth_number}
                          </Text>
                          <ToothIcon
                            isInitial={false}
                            id={tooth?.tooth_number}
                            onClick={onClickTooth}
                            teethData={teethData}
                          />
                        </Flex>
                        {teethData?.[tooth?.tooth_number]
                          ?.hasPendingProcedures && <WarningIcon />}
                      </Flex>
                    ))}
                  </Grid>
                </Flex>
                <Box
                  mt="30px"
                  borderTop="1px solid rgba(0, 0, 0, 0.16)"
                  width="100%"
                />
                {getSelectedToothData(
                  odontogramData,
                  isAllToothSelected?.tooth_number
                )?.tooth_number && isAllToothSelected?.isSelected ? (
                  <Flex
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width="100%"
                      mt="22px"
                      pr="20px"
                    >
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Text
                          color="#000"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mb="5px"
                        >
                          {`# ${
                            getSelectedToothData(
                              odontogramData,
                              isAllToothSelected?.tooth_number
                            )?.tooth_number
                          } ${t("web_client.add_diagnosis")}`}
                        </Text>
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mb="5px"
                          ml="5px"
                        >
                          {t("web_client.add_diagnosis_two")}
                        </Text>
                      </Flex>

                      <Flex flexDirection="row" alignItems="center">
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mr="20px"
                        >
                          {t("add_periodontological_note")}
                        </Text>
                        <Button
                          size="sm"
                          variant="ghost"
                          backgroundColor="rgba(0, 0, 0, 0.06)"
                          _focus={{ outline: "none" }}
                          _hover={{ background: "#9F7AEA" }}
                          onClick={handleOpenPeriodontalNotes}
                          isDisabled={isReadOnly}
                        >
                          <Plus size="15px" color="#000000" />
                        </Button>
                      </Flex>
                    </Flex>

                    <Flex
                      width="100%"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      mt="20px"
                    >
                      {getSelectedToothData(
                        odontogramData,
                        isAllToothSelected?.tooth_number
                      )?.historical?.map((toothData, index) => (
                        <Flex
                          key={`${toothData?.name}-${index}`}
                          mb="5"
                          flexDirection="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="80px"
                          >
                            <Text
                              color="#000"
                              fontSize="18px"
                              fontWeight="400"
                              lineHeight="27px"
                              textTransform="capitalize"
                              textAlign="left"
                            >
                              {toothData?.periodontologicalNote
                                ? t("add_medical_note")
                                : dentalObservations.find(
                                    (observation) =>
                                      observation?.value ===
                                      toothData?.observation
                                  )?.label || "No data"}
                            </Text>
                          </Box>

                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="100px"
                          >
                            <Text
                              color="rgba(0, 0, 0, 0.36)"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="21px"
                              textAlign="left"
                            >
                              {toothData?.periodontologicalNote
                                ? t("add_medical_note")
                                : toothData?.name?.replace("_", " ") ||
                                  "No data"}
                            </Text>
                          </Box>

                          <Tooltip
                            label={
                              toothData?.observationText ||
                              toothData?.periodontologicalNote ||
                              "No data"
                            }
                          >
                            <Box width="300px">
                              <Text
                                color="#000"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="21px"
                                textAlign="left"
                              >
                                {truncate(
                                  toothData?.observationText ||
                                    toothData?.periodontologicalNote ||
                                    "No data",
                                  {
                                    length: 65,
                                  }
                                )}
                              </Text>
                            </Box>
                          </Tooltip>

                          <Box
                            display="flex"
                            alignItems="flex-start"
                            width="200px"
                          >
                            <Text
                              color="rgba(0, 0, 0, 0.36)"
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="21px"
                              textTransform="Capitalize"
                              textAlign="left"
                            >
                              {toothData?.createdBy?.name || "No data"}
                            </Text>
                          </Box>

                          <Text
                            color="rgba(0, 0, 0, 0.36)"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="21px"
                          >
                            {moment(toothData?.createdAt)
                              .format("DD-MM-YYYY")
                              .toUpperCase()}
                          </Text>

                          <Tooltip
                            label={toothData?.procedures?.map(
                              (procedure, procedureIndex) =>
                                toothData?.procedures?.length ===
                                procedureIndex + 1
                                  ? `${procedure?.name}. `
                                  : `${procedure?.name}, `
                            )}
                          >
                            <Flex
                              background="#FAF5FF"
                              width="120px"
                              height="30px"
                              alignItems="center"
                              justifyContent="center"
                              borderRadius="5px"
                            >
                              <Text
                                color="#9F7AEA"
                                fontSize="13px"
                                fontWeight="400"
                                lineHeight="24px"
                              >
                                {toothData?.periodontologicalNote
                                  ? t("add_medical_note")
                                  : truncate(
                                      toothData?.procedures?.[0]?.name ||
                                        "No data",
                                      {
                                        length: 18,
                                      }
                                    )}
                              </Text>
                            </Flex>
                          </Tooltip>

                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            width="24px"
                            height="24px"
                            background={toothData?.isDone ? "#68D391" : "#000"}
                            opacity={toothData?.isDone ? "1" : "0.3"}
                            borderRadius="100%"
                            cursor={
                              toothData?.isDone ? "not-allowed" : "pointer"
                            }
                            onClick={() =>
                              toothData?.isDone || isReadOnly
                                ? null
                                : handleMarkProcedureDone(toothData, false)
                            }
                          >
                            <CheckIcon fillColor="#FFF" />
                          </Box>

                          <Box>
                            <Menu>
                              <MenuButton
                                isDisabled={toothData?.isDone || isReadOnly}
                                as={Button}
                                rightIcon={
                                  <ThreeDotsVertical
                                    color="#000000"
                                    size={24}
                                  />
                                }
                              />
                              <MenuList background="white">
                                {!toothData?.isDone && (
                                  <MenuItem
                                    minH="40px"
                                    onClick={() =>
                                      handleMarkProcedureDone(toothData, false)
                                    }
                                  >
                                    <span>{t("complete")}</span>
                                  </MenuItem>
                                )}
                                <MenuItem
                                  minH="48px"
                                  onClick={() =>
                                    onEditProcedure(
                                      toothData,
                                      false,
                                      !!toothData?.periodontologicalNote
                                    )
                                  }
                                >
                                  <span>{t("edit")}</span>
                                </MenuItem>
                                <MenuItem
                                  minH="40px"
                                  onClick={() => handleRemove(toothData, false)}
                                >
                                  <span>{t("web_client.delete")}</span>
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </Box>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    mt="10px"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Text
                      mt="22px"
                      color="rgba(0, 0, 0, 0.36)"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="19.2px"
                      mb="5px"
                    >
                      {t("odontogram_title")}
                    </Text>

                    {isAllToothSelected?.tooth_number && (
                      <Flex flexDirection="row" alignItems="center">
                        <Text
                          color="rgba(0, 0, 0, 0.64)"
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="19.2px"
                          mr="20px"
                        >
                          {t("add_periodontological_note")}
                        </Text>
                        <Button
                          size="sm"
                          variant="ghost"
                          backgroundColor="rgba(0, 0, 0, 0.06)"
                          _focus={{ outline: "none" }}
                          _hover={{ background: "#9F7AEA" }}
                          onClick={handleOpenPeriodontalNotes}
                          isDisabled={isReadOnly}
                        >
                          <Plus size="15px" color="#000000" />
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                )}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>

      <Modal
        motionPreset="slideInBottom"
        isOpen={isWarningModalOpen}
        onClose={onCloseWarningModal}
        isCentered
      >
        <ModalOverlay background="rgba(0, 0, 0, 0.80)" />
        <ModalContent
          backgroundColor="white"
          minHeight="250px"
          minWidth="645px"
        >
          <ModalHeader>
            <Text
              color="#000"
              fontSize="20px"
              fontWeight="700"
              lineHeight="30px"
            >
              {t("switch_to_evolutionary")}
            </Text>
          </ModalHeader>
          <ModalBody backgroundColor="white">
            <Text>{t("switch_description")}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onCloseWarningModal()
                setActiveTab(0)
              }}
            >
              <Text
                color="#000"
                fontSize="16px"
                fontWeight="400"
                lineHeight="19.2px"
              >
                {t("cancel")}
              </Text>
            </Button>
            <Button
              variant="solid"
              background="rgba(0, 0, 0, 0.06)"
              color="#000"
              _hover={{
                background: "green",
                color: "white",
              }}
              onClick={() => {
                setActiveTab(1)
                onCloseWarningModal()
              }}
            >
              <Text fontSize="16px" fontWeight="400" lineHeight="19.2px">
                {t("confirm")}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        motionPreset="slideInBottom"
        size="xl"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay background="rgba(0, 0, 0, 0.80)" />
        <ModalContent
          backgroundColor="white"
          minHeight="500px"
          minWidth="645px"
          overflow="hidden !important"
        >
          <ModalHeader>
            <Text
              color="#000"
              fontSize="20px"
              fontWeight="700"
              lineHeight="30px"
            >
              {`#${t("tooth")} ${toothToUpdate?.tooth_number}`}
            </Text>
            <Text
              color="rgba(0, 0, 0, 0.36)"
              fontSize="18px"
              fontWeight="400"
              lineHeight="27px"
              textTransform="capitalize"
            >
              {toothToUpdate?.name?.replace("_", " ")}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            background="rgba(0, 0, 0, 0.06)"
            borderRadius="9999px"
            color="#000"
            _focus={{ outline: "none" }}
          />
          <ModalBody backgroundColor="white">
            <Flex justifyContent="space-between" width="100%">
              <Box
                minHeight="280px"
                height="100%"
                width="100%"
                padding="22px"
                borderTop="1px solid rgba(0, 0, 0, 0.08)"
                borderBottom="1px solid rgba(0, 0, 0, 0.08)"
                borderRight="1px solid rgba(0, 0, 0, 0.08)"
              >
                <Select
                  hideSelectedOptions
                  defaultValue={dentalObservations.find(
                    (observation) =>
                      observation?.value === toothToUpdate?.observation
                  )}
                  placeholder={t("observation")}
                  width="240px"
                  styles={customStyles}
                  options={dentalObservations}
                  onChange={(data) => {
                    setToothToUpdate((currentValues) => ({
                      ...currentValues,
                      observation: data?.value,
                    }))
                  }}
                />
                <Textarea
                  defaultValue={toothToUpdate?.observationText}
                  width="240px"
                  minHeight="180px"
                  mt="10px"
                  borderRadius="8px 8px 0px 0px"
                  placeholder={t("dentist_textarea_placeholder")}
                  color="#000 !important"
                  border="1px solid rgba(0, 0, 0, 0.08)  !important"
                  _placeholder={{
                    color: "#000",
                  }}
                  _focus={{ outline: "none" }}
                  onChange={(e) => {
                    e.persist()
                    setToothToUpdate((currentValues) => ({
                      ...currentValues,
                      observationText: e?.target?.value,
                    }))
                  }}
                />
              </Box>

              <Box
                minHeight="280px"
                height="100%"
                width="100%"
                padding="22px"
                borderTop="1px solid rgba(0, 0, 0, 0.08)"
                borderBottom="1px solid rgba(0, 0, 0, 0.08)"
                borderLeft="1px solid rgba(0, 0, 0, 0.08)"
              >
                <Search
                  onSelectValue={(data) => {
                    setToothToUpdate((currentValues) => ({
                      ...currentValues,
                      procedures: data,
                    }))
                  }}
                  defaultValue={toothToUpdate?.procedures}
                  options={dentalProcedures}
                />
              </Box>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClose()
                setIsEditing(false)
              }}
            >
              <Text
                color="#000"
                fontSize="16px"
                fontWeight="400"
                lineHeight="19.2px"
              >
                {t("cancel")}
              </Text>
            </Button>
            <Button
              variant="solid"
              background="rgba(0, 0, 0, 0.06)"
              color="#000"
              _hover={{
                background: "green",
                color: "white",
              }}
              onClick={
                isEditing ? () => handleEdit(false) : () => handleCreate(false)
              }
            >
              <Text fontSize="16px" fontWeight="400" lineHeight="19.2px">
                {isEditing ? t("update") : t("web_client.add")}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        motionPreset="slideInBottom"
        size="xl"
        isOpen={isPeriodontalNotesOpen}
        onClose={onClosePeriodontalNotes}
        isCentered
      >
        <ModalOverlay background="rgba(0, 0, 0, 0.80)" />
        <ModalContent
          backgroundColor="white"
          minHeight="500px"
          minWidth="645px"
          overflow="hidden !important"
        >
          <ModalHeader>
            <Text
              color="#000"
              fontSize="20px"
              fontWeight="700"
              lineHeight="30px"
            >
              {t("add_periodontological_note")}
            </Text>
          </ModalHeader>
          <ModalCloseButton
            background="rgba(0, 0, 0, 0.06)"
            borderRadius="9999px"
            color="#000"
            _focus={{ outline: "none" }}
          />
          <ModalBody backgroundColor="white">
            <Flex justifyContent="space-between" width="100%">
              <Textarea
                defaultValue={toothToUpdate?.periodontologicalNote}
                width="100%"
                minHeight="180px"
                mt="10px"
                borderRadius="8px 8px 0px 0px"
                placeholder={t("web_client.notes_title")}
                color="#000 !important"
                border="1px solid rgba(0, 0, 0, 0.08)  !important"
                _placeholder={{
                  color: "#000",
                }}
                _focus={{ outline: "none" }}
                onChange={(e) => {
                  e.persist()
                  setToothToUpdate((currentValues) => ({
                    ...currentValues,
                    id: isEditing
                      ? toothToUpdate?.id
                      : Date.now().toString(36) +
                        Math.random().toString(36).substr(2),
                    periodontologicalNote: e?.target?.value,
                    tooth_number: isAllToothSelected?.tooth_number,
                    isDone: false,
                    createdAt: isEditing
                      ? toothToUpdate?.createdAt
                      : Date.now(),
                    createdBy: isEditing
                      ? toothToUpdate?.createdBy
                      : {
                          email: user.isImpersonating
                            ? user.impersonatingEmail
                            : user.email,
                          name: `${user?.medicProfile?.title} ${user?.medicProfile?.displayName}`,
                          profession: user?.medicProfile?.profession,
                        },
                  }))
                }}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              colorScheme="blue"
              mr={3}
              onClick={() => {
                onClosePeriodontalNotes()
                setIsEditing(false)
              }}
            >
              <Text
                color="#000"
                fontSize="16px"
                fontWeight="400"
                lineHeight="19.2px"
              >
                {t("cancel")}
              </Text>
            </Button>
            <Button
              variant="solid"
              background="rgba(0, 0, 0, 0.06)"
              color="#000"
              _hover={{
                background: "green",
                color: "white",
              }}
              onClick={
                isEditing ? () => handleEdit(true) : () => handleCreate(true)
              }
            >
              <Text fontSize="16px" fontWeight="400" lineHeight="19.2px">
                {isEditing ? t("update") : t("web_client.add")}
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Odontogram
