/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import { Box, Flex, Text, Textarea, useMediaQuery } from "@chakra-ui/react"
import { Formik } from "formik"

// Components
import ImageUploader from "../../../../molecules/ImageUploader"
import Tooltip from "../../../../atoms/Tooltip"
import Switch from "../../../../atoms/Switch"

// Chackra
import CountriesSelector from "../../../../chakra/CountriesSelector"
import LocationSelector from "../../../../chakra/LocationSelector"

// Styles
import { Section, Images } from "../../styles"

export const Business = ({
  coordsError,
  businessFormValues,
  propertiesMissing,
  setImageCompanyURL,
  setImageCompanyStampURL,
  setBusinessFormValues,
  showCoordsError,
  onChangeBusinessView,
  setWithChanges,
  organizations,
  accessMode,
}) => {
  const [multipleOfficesEnabled, setMultipleOfficesEnabled] = useState(true)
  const [isMobile] = useMediaQuery("(max-width: 800px)")
  const { t } = useTranslation()
  const formRef = useRef()
  const valuesForm = formRef.current?.values

  useEffect(() => {
    if (valuesForm?.offices) {
      setBusinessFormValues(valuesForm)
    }
  }, [JSON.stringify(valuesForm?.offices)])

  const { addToast } = useToasts()
  const [userCoords, setUserCords] = useState({})

  const onChangeBusinessLocation = (key = "", value) => {
    if (key === "coordinates") {
      if (value?.latitude || value?.longitude) {
        showCoordsError(false)
      } else {
        showCoordsError(true)
      }
    }

    if (key === "country" && value !== businessFormValues?.address?.country) {
      onChangeBusinessView({ value: null }, "bankName")
      onChangeBusinessView({ value: null }, "bankAccountType")
      onChangeBusinessView({ value: null }, "bankAccountCurrency")
      onChangeBusinessView({ value: null }, "bankAccount")
    }

    if (key.includes("office")) {
      formRef.current.setFieldValue(key, value)
      const keyDetails = key.split(".")
      const officeIndex = +keyDetails[1]
      const officeKey = keyDetails[2]

      const updatedOffices = businessFormValues?.offices?.map(
        (office, index) => {
          if (index === officeIndex) {
            return { ...office, [officeKey]: value }
          }
          return office
        }
      )

      setBusinessFormValues((currentValues) => ({
        ...currentValues,
        offices: updatedOffices,
      }))
    } else {
      formRef.current.setFieldValue(`address.${key}`, value)
      setBusinessFormValues((currentValues) => ({
        ...currentValues,
        address: {
          ...currentValues?.address,
          [key]: value,
        },
      }))
    }
    setWithChanges(true)
  }

  const successCallback = (position) => {
    setUserCords(position?.coords)
  }

  const errorCallback = () => {
    addToast(t("location_error"), { appearance: "error" })
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }, [])

  return (
    <Section>
      <Images>
        <ImageUploader
          src={
            get(businessFormValues, "companyStamp")
              ? get(
                  businessFormValues,
                  ["companyStamp", "blobUrl"],
                  businessFormValues.companyStamp
                )
              : null
          }
          onImageLoaded={(field, data) => {
            setImageCompanyStampURL(data)
            setBusinessFormValues((currentValues) => ({
              ...currentValues,
              [field]: data,
            }))
            setWithChanges(true)
          }}
          fieldName="companyStamp"
          width={130}
          height={130}
          emptyMessage={t("web_client.medical_stamp_label")}
          showError={get(propertiesMissing, "companyStamp", false)}
        />
        <Tooltip title={t("web_client.business_logo_tooltip")}>
          <ImageUploader
            src={
              get(businessFormValues, "companyLogo")
                ? get(
                    businessFormValues,
                    ["companyLogo", "blobUrl"],
                    businessFormValues.companyLogo
                  )
                : null
            }
            onImageLoaded={(field, data) => {
              setImageCompanyURL(data)
              setBusinessFormValues((currentValues) => ({
                ...currentValues,
                [field]: data,
              }))
              setWithChanges(true)
            }}
            fieldName="companyLogo"
            width={130}
            height={130}
            emptyMessage="Logo"
            showError={get(propertiesMissing, "companyLogo", false)}
          />
        </Tooltip>
      </Images>
      <Formik
        innerRef={formRef}
        initialValues={{ ...businessFormValues, multipleOfficesEnabled }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false)
        }}
      >
        {(props) => (
          <>
            <Flex
              direction="column"
              width="768px"
              backgroundColor="rgb(247, 250, 252)"
              borderRadius="10px"
              mt="100px"
            >
              {/* <Flex
                backgroundColor="lightgrey"
                width="100%"
                paddingLeft="20px"
                height="40px"
              >
                <Flex>
                  <Text htmlFor="multipleOfficesEnabled" mb="0">
                    {t("web_client.register_prescription_multiple_offices")}
                  </Text>
                  <Switch
                    id="multipleOfficesEnabled"
                    value={multipleOfficesEnabled}
                    checked={multipleOfficesEnabled}
                    onChange={(e) => {
                      e.persist()
                      setMultipleOfficesEnabled(e.target.checked)
                      props.setFieldValue(
                        "multipleOfficesEnabled",
                        e.target.checked
                      )
                    }}
                  />
                </Flex>
              </Flex> */}
              <Box width="100%" paddingLeft={isMobile ? "20px" : "0"}>
                <CountriesSelector
                  onChange={onChangeBusinessLocation}
                  values={
                    props?.values?.address
                      ? {
                          ...props?.values?.address,
                          offices: props?.values?.offices,
                          multipleOfficesEnabled: !!multipleOfficesEnabled,
                        }
                      : {
                          multipleOfficesEnabled: !!multipleOfficesEnabled,
                        }
                  }
                  hasSubmitions={1}
                  errors={{ country: propertiesMissing?.countryBusiness }}
                  organizations={organizations}
                  accessMode={accessMode}
                  isFromAccount
                />
              </Box>
              {!multipleOfficesEnabled && (
                <>
                  <Box width="100%">
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width="97%"
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Box marginRight="10px" height="22px">
                          *
                        </Box>
                        <Text fontSize="14px" fontWeight="600" color="#1A202C">
                          {t("address_street1_label")}
                        </Text>
                      </Flex>
                      <Box>
                        <Textarea
                          _hover={{}}
                          variant="outline"
                          _placeholder={{
                            color: "gray.500",
                          }}
                          focusBorderColor="#E2E8F0"
                          border="1px solid #E2E8F0 !important"
                          borderRadius="6px"
                          color="gray.500"
                          fontSize="16px"
                          placeholder=""
                          width={isMobile ? "320px" : "528px"}
                          height="32px"
                          type="text"
                          name="addressDetails"
                          id="addressDetails"
                          onChange={(e) =>
                            onChangeBusinessLocation(
                              "addressDetails",
                              e.target.value
                            )
                          }
                          defaultValue={get(
                            businessFormValues,
                            "address.addressDetails",
                            null
                          )}
                        />
                      </Box>
                    </Flex>
                  </Box>
                  <Box width="100%">
                    <Flex
                      flexDirection={isMobile ? "column" : "row"}
                      minHeight="84px"
                      padding={isMobile ? "10px 20px" : "10px 0"}
                      justifyContent="space-between"
                      alignItems={isMobile ? "flex-start" : "center"}
                      width="97%"
                    >
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        marginLeft={isMobile ? "0" : "24px"}
                      >
                        <Box marginRight="10px" height="22px">
                          *
                        </Box>
                        <Text fontSize="14px" fontWeight="600" color="#1A202C">
                          {t("address_street_cords")}
                        </Text>
                      </Flex>
                      <Box>
                        <LocationSelector
                          errors={{
                            cordsError:
                              coordsError || !!propertiesMissing?.address,
                          }}
                          setCustomValue={onChangeBusinessLocation}
                          values={businessFormValues?.address}
                          userDefaultCoords={userCoords}
                        />
                      </Box>
                    </Flex>
                  </Box>
                </>
              )}
            </Flex>
          </>
        )}
      </Formik>
    </Section>
  )
}

Business.propTypes = {
  propertiesMissing: PropTypes.shape().isRequired,
  coordsError: PropTypes.bool.isRequired,
  businessFormValues: PropTypes.shape().isRequired,
  setImageCompanyURL: PropTypes.func.isRequired,
  setImageCompanyStampURL: PropTypes.func.isRequired,
  setBusinessFormValues: PropTypes.func.isRequired,
  showCoordsError: PropTypes.func.isRequired,
  onChangeBusinessView: PropTypes.func.isRequired,
  setWithChanges: PropTypes.func.isRequired,
}

export default Business
