export const SESSIONS = "/sessions"
export const AGENDA = "/agenda"
export const HELP = "/help"
export const HISTORY = "/history"
export const QUESTIONS = "/questions"
export const PRESCRIPTION = "/prescription"
export const CLINICAL = "/clinical"
export const MEDICAL = "/medical-images"
export const PAYMENT = "/payment"
export const SERVICES = "/services"
export const ACCOUNT = "/account"
export const VERIFY = "/verify"
export const SETUP_ACCOUNT_USER_TYPE = "/setup-account/userType"
export const SETUP_ACCOUNT = "/setup-account"
export const LOGIN = "/login"
export const OLD_LOGIN = "/app/login"
export const SIGN_IN = "/signin"
export const HOME = "/"
export const CONFIRM = "/confirm"
export const SETTINGS = "/settings"
export const DASHBOARD = "/dashboard"
export const CONTACTS = "/contacts"
export const FORGOT_PASSWORD = "/forgot-password"
export const PROFILE = "/profile/:id"
export const RESET_PASSWORD = "/reset-password"
export const ORG_EMPLOYEE_REGISTER = "/register/:orgId"
export const ODONTOGRAM = "/check/odontogram/:token"
export const BUSINESS_REGISTER = "/business/register"
