import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useHistory, Link } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useTranslation } from "react-i18next"
import get from "lodash.get"
import { v4 as uuidv4 } from "uuid"
import CustomModal from "../../atoms/CustomModal"
import { prepareMessage } from "../../../utils/helpers"
import {
  v3sessionsCloseUrl,
  // eslint-disable-next-line no-unused-vars
  sessionsDiscountUrl,
  getProviderPricingUrl,
  sessionsDonateUrl,
} from "../../../utils/App"
import { withFirebase } from "../../../utils/Firebase"
import { InitialAvatar, Name } from "../../atoms/Card/styles"
import Theme from "../../../styles/theme"
import {
  HeaderWrapper,
  SubmitButton,
  ModalWrapper,
  ModalOptions,
  DiscountsWrapper,
  NameWrapper,
  ContactLink,
} from "./styles"

const ChatHeader = ({
  name,
  showButton,
  firebase,
  handleClick,
  session,
  serverTimeOffset,
  user,
  donated,
  info,
  sessionDiscount,
  isDependentChat,
}) => {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const history = useHistory()
  const [isDonating, setIsDonating] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isConfirming, setIsConfirming] = useState(false)
  const [sessionDonated, setSessionDonated] = useState(donated)
  const [processingDiscount, setProcessingDiscount] = useState(false)
  const [discount, setDiscount] = useState(0)
  const [isContact, setIsContact] = useState(false)

  useEffect(() => {
    if (isButtonLoading) {
      setTimeout(() => {
        setIsButtonLoading(false)
      }, 1000 / 5)
    }
  }, [isButtonLoading])

  useEffect(() => {
    const processDiscount = async () => {
      try {
        const authToken = await firebase.getIdToken()
        const params = {
          email: user.isImpersonating ? user.impersonatingEmail : user.email,
        }
        const url = new URL(getProviderPricingUrl)
        Object.keys(params).forEach((k) => {
          url.searchParams.append(k, params[k])
        })

        const providerPricingData = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        })

        if (providerPricingData.ok) {
          const pricing = await providerPricingData.json()
          const response = await fetch(sessionsDiscountUrl, {
            method: "POST",
            body: JSON.stringify({
              chatId: session,
              discount,
              pricing,
            }),
            headers: {
              Authorization: `Bearer ${authToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          if (response.ok) {
            addToast(t("discount_applied"), { appearance: "success" })
            setIsDiscountModalOpen(false)
            setProcessingDiscount(false)
          } else {
            setProcessingDiscount(false)
            addToast(t("unexpected_error"), { appearance: "error" })
          }
        } else {
          setProcessingDiscount(false)
          addToast(t("unexpected_error"), { appearance: "error" })
        }
      } catch (err) {
        setProcessingDiscount(false)
        addToast(t("unexpected_error"), { appearance: "error" })
      }
    }

    if (processingDiscount) {
      processDiscount()
    }
  }, [session, discount, processingDiscount])

  useEffect(() => {
    const checkContact = async () => {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        info.clientEmail
      )
      if (data && data.docs.length > 0) {
        setIsContact(true)
      }
    }
    if (user && info) {
      checkContact()
    }
  }, [user, info])

  const sendMessage = (msg) => {
    const prepMsg = prepareMessage(
      user,
      serverTimeOffset,
      msg,
      msg.type || "text",
      msg.targetTokens || []
    )
    return firebase.pushMessage({ chat: session, message: prepMsg })
  }

  const donateSession = async () => {
    const tokens = localStorage.getItem(`targetTokens-${session}`)
    const authToken = await firebase.getIdToken()
    sendMessage({
      _id: uuidv4(),
      text: "session_donated",
      type: "system",
      targetTokens: JSON.parse(tokens),
    })

    const response = await fetch(sessionsDonateUrl, {
      method: "POST",
      body: JSON.stringify({
        chatId: session,
      }),
      headers: {
        Authorization: `Bearer ${authToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })

    if (response.ok) {
      setSessionDonated(true)
      setIsDonating(false)
    }
  }

  const handleOptionClick = (option) => {
    if (option === "finish") {
      setSelectedOption("finish")
      setIsModalOpen(true)
    } else {
      setIsDiscountModalOpen(false)
      setIsDonating(true)
      setSelectedOption("donate")
      setIsModalOpen(true)
    }
  }

  const handleModalCancel = () => {
    setIsModalOpen(false)
    setSelectedOption(null)
    setIsDonating(false)
  }

  const startVideoSession = () => {
    const tokens = localStorage.getItem(`targetTokens-${session}`)
    const title = get(user, ["medicProfile", "title"])
    const providerName = title
      ? `${title} ${get(user, ["medicProfile", "displayName"])}`
      : get(user, ["medicProfile", "displayName"])
    sendMessage({
      _id: uuidv4(),
      text: `video_session_started|${session}|${providerName}`,
      type: "system",
      targetTokens: JSON.parse(tokens),
    })
    handleClick()
  }

  const handleFinish = async () => {
    try {
      const idToken = await firebase.getIdToken()
      setIsConfirming(true)
      if (selectedOption === "finish") {
        // const date = new Date()
        const params = {
          slotID: info.appointmentId,
        }
        const query = Object.keys(params)
          .map(
            (k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
          )
          .join("&")
        const response = await fetch(`${v3sessionsCloseUrl}?${query}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })

        if (response.ok) {
          setIsConfirming(false)
          setIsModalOpen(false)
          addToast(t("session_finished"), { appearance: "success" })
          setTimeout(() => {
            history.push("/sessions")
          }, 1000)
        }
      } else {
        setIsConfirming(false)
        setIsModalOpen(false)
        donateSession()
      }
    } catch (e) {
      setIsConfirming(false)
      addToast(t("unexpected_error"), { appearance: "error" })
    }
  }

  const applyDiscount = (amount) => {
    setDiscount(amount)
    setProcessingDiscount(true)
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          event.stopPropagation()
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  return (
    <>
      <HeaderWrapper>
        <div>
          <InitialAvatar>
            <span>{name[0]}</span>
          </InitialAvatar>
          <NameWrapper>
            <Name>
              {isDependentChat ? `${name} (Atendiendo Dependiente)` : name}
            </Name>
            {isContact && showButton && (
              <Link
                to={{
                  pathname: "/contacts",
                  state: {
                    contact: {
                      email: info.clientEmail,
                      displayName: info.clientName,
                    },
                    session,
                  },
                }}
              >
                <ContactLink>{t("web_client.look_contact")}</ContactLink>
              </Link>
            )}
          </NameWrapper>
        </div>
        {showButton && (
          <div>
            {info?.sessionType !== "faceToFace" && (
              <SubmitButton
                iconName="video"
                isLoading={isButtonLoading}
                type="button"
                onClick={startVideoSession}
                color={Theme.color.secondary}
                small
              />
            )}

            {/* {!donated && info?.sessionType !== "faceToFace" && (
              <SubmitButton
                isLoading={isDonating}
                type="submit"
                onClick={() => {
                  setIsDiscountModalOpen(true)
                }}
                color={Theme.color.secondary}
                medium
              >
                {t("apply_discount")}
              </SubmitButton>
            )} */}
            <SubmitButton
              isLoading={isModalOpen}
              type="submit"
              onClick={() => handleOptionClick("finish")}
              color="red"
              medium
            >
              {t("finish_session")}
            </SubmitButton>
          </div>
        )}
      </HeaderWrapper>
      <CustomModal
        modalOpen={isModalOpen}
        setModalOpen={setIsModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p>
                {selectedOption === "finish"
                  ? t("system_session_check_before_finish")
                  : t("system_session_check_before_donate")}
              </p>
              <div style={{ marginTop: "50px" }}>
                <button type="button" onClick={handleModalCancel}>
                  {t("cancel")}
                </button>
                <SubmitButton
                  isLoading={isConfirming}
                  type="button"
                  onClick={handleFinish}
                  color={Theme.color.secondary}
                  small
                >
                  {t("confirm")}
                </SubmitButton>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
      <CustomModal
        modalOpen={isDiscountModalOpen}
        setModalOpen={setIsDiscountModalOpen}
        showClose={false}
      >
        <ModalWrapper isFull>
          <ModalOptions>
            <section>
              <p
                style={{
                  marginBottom: "10px",
                }}
              >
                {sessionDiscount > 0
                  ? t("already_applied_discount_text")
                  : t("apply_discount_text")}
              </p>
              {sessionDiscount === 0 && (
                <DiscountsWrapper isCancel>
                  <SubmitButton
                    disabled={isDonating}
                    isLoading={isDonating}
                    type="submit"
                    onClick={() =>
                      donated || sessionDonated || isDonating
                        ? null
                        : handleOptionClick("donate")
                    }
                    color={Theme.color.secondary}
                  >
                    {t("donate_session")}
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              {sessionDiscount === 0 && (
                <DiscountsWrapper>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.75}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.75)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    75%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.5}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.5)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    50%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.25}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.25)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    25%
                  </SubmitButton>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0.1}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0.1)
                    }
                    color={Theme.color.secondary}
                    small
                  >
                    10%
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              {sessionDiscount > 0 && (
                <DiscountsWrapper isCancel>
                  <SubmitButton
                    disabled={processingDiscount}
                    isLoading={processingDiscount && discount === 0}
                    type="submit"
                    onClick={() =>
                      processingDiscount ? null : applyDiscount(0)
                    }
                    color="red"
                  >
                    {t("remove_discount")}
                  </SubmitButton>
                </DiscountsWrapper>
              )}
              <div style={{ marginTop: "50px" }}>
                <button
                  type="button"
                  onClick={() => setIsDiscountModalOpen(false)}
                >
                  {t("cancel")}
                </button>
              </div>
            </section>
          </ModalOptions>
        </ModalWrapper>
      </CustomModal>
    </>
  )
}

ChatHeader.defaultProps = {
  name: "",
  showButton: true,
}

ChatHeader.propTypes = {
  name: PropTypes.string,
  showButton: PropTypes.bool,
  firebase: PropTypes.shape().isRequired,
}

export default withFirebase(ChatHeader)
