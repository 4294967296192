/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { StarRate } from "@styled-icons/material/StarRate"
import { Cancel } from "@styled-icons/material/Cancel"
import get from "lodash.get"
import { useToasts } from "react-toast-notifications"
import SidebarItems from "./items"
import SignOutButton from "../SignOut"
import { AuthUserContext } from "../../../context/Session"
import LanguageSelector from "../LanguageSelector"
import { InitialAvatar } from "../Card/styles"
import Logo from "../Logo"
import Tooltip from "../Tooltip"
import Switch from "../Switch"
import DoctorList from "../DoctorList"

// Images
import defaultPicture from "../../../static/images/others/no-photo.jpeg"

function Sidebar(props, { defaultActive }) {
  const { t } = useTranslation()
  const { addToast } = useToasts()
  const authUser = useContext(AuthUserContext)
  const location = get(props, ["history", "location"])
  const lastActiveIndexString = localStorage.getItem("lastActiveIndex")
  const lastActiveIndex = Number(lastActiveIndexString)
  const [isDoctorListVisible, setIsDoctorListVisible] = useState(false)
  const [isCheckingState, setIsCheckingState] = useState(true)
  const [activeIndex, setActiveIndex] = useState(
    lastActiveIndex || defaultActive
  )
  const [isOnline, setIsOnline] = React.useState(false)
  const [offlineAlert, setOfflineAlert] = React.useState(false)
  const isActive = get(authUser, ["medicProfile", "status"]) === "active"
  const isBasicPlan = get(authUser, ["medicProfile", "plan"]) === "basic"

  function changeActiveIndex(newIndex) {
    localStorage.setItem("lastActiveIndex", newIndex)
    setActiveIndex(newIndex)
  }

  function getPath(path) {
    if (path && path.length > 0 && path.charAt(0) !== "/") {
      return `/${path}`
    }
    return path
  }

  const handleConnectStatus = async () => {
    try {
      if (!isActive) {
        addToast(t("unexpected_error_no_active_account"), {
          appearance: "error",
        })
      } else {
        const newStatus = !isOnline
        await props.firebase.toggleOnlineStatus({
          email: authUser.email,
          data: {
            online: newStatus,
            indexDocument: true,
          },
        })

        setIsOnline(newStatus)
        addToast(t("web_client.saved_successfully"), { appearance: "success" })
      }
    } catch (error) {
      window.Honeybadger?.notify(`Online/Offline ${!isOnline} error: ${error}`)
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
    }
  }

  useEffect(() => {
    const activeItem = SidebarItems.findIndex(
      (item) => getPath(item.route) === getPath(get(location, "pathname"))
    )
    changeActiveIndex(activeItem)
  }, [location])

  const makeSuscription = () => {
    const { firebase } = props
    setIsCheckingState(true)
    const unsubscribe = firebase.subscribeToProviderStatus({
      doctor: authUser?.email,
      onSnapshot: (s) => {
        const user = s.data()
        // if (user.online !== isOnline) {
        setIsOnline(user?.online)
        // }
        setIsCheckingState(false)
      },
    })
    return () => {
      if (unsubscribe) unsubscribe()
    }
  }

  useEffect(() => {
    const profile = get(authUser, "medicProfile")
    if (profile && profile.userType !== "medicalAssistant") {
      makeSuscription()
    }
  }, [authUser])

  useEffect(() => {
    if (!isOnline && !isCheckingState) {
      setOfflineAlert(true)
    } else {
      setOfflineAlert(false)
    }
  }, [isOnline])

  return (
    <SidebarParent>
      <div style={{ position: "fixed" }}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        {get(authUser, ["medicProfile", "userType"]) !== "medicalAssistant" && (
          <Item>
            <Switch
              id="isOnline"
              value={isOnline && isActive}
              checked={isOnline && isActive}
              onChange={handleConnectStatus}
            />
            <OnlineLabel>
              {t(isOnline && isActive ? "online_status" : "offline_status")}
            </OnlineLabel>
            {offlineAlert && (
              <SwitchTooltipContainer>
                <SwitchTooltipShadows>
                  <SwitchTooltipTitle>
                    {t("offline_alert_title")}
                    <Cancel size={24} onClick={() => setOfflineAlert(false)} />
                  </SwitchTooltipTitle>
                  <SwitchTooltipContent>
                    {t("offline_alert")}
                  </SwitchTooltipContent>
                </SwitchTooltipShadows>
              </SwitchTooltipContainer>
            )}
          </Item>
        )}
        {SidebarItems.filter((i) => !i.isFooter).map((item, index) => {
          if (
            (item.protected &&
              get(authUser, ["medicProfile", "userType"]) !== "provider") ||
            (item.onlyAssistant &&
              get(authUser, ["medicProfile", "userType"]) !==
                "medicalAssistant") ||
            (item.allowImpersonated &&
              get(authUser, ["medicProfile", "userType"]) ===
                "medicalAssistant" &&
              !authUser.isImpersonating) ||
            (item.onlyAssistant &&
              get(authUser, ["medicProfile", "userType"]) ===
                "medicalAssistant" &&
              authUser.isImpersonating)
          )
            return null
          if (item.isFooter) return null
          const IconItem = item.icon
          const isQuestionsDisabled = item?.key === "questions" && isBasicPlan
          const menuTitle = isQuestionsDisabled
            ? t("web_client.premium_only", { feature: t(item.lang) })
            : t(item.lang)
          const linkRoute = isQuestionsDisabled
            ? "#"
            : authUser && authUser.isImpersonating && item.alternateRoue
            ? item.alternateRoue
            : item.route
          return (
            <Link
              isDisabled={isQuestionsDisabled}
              to={linkRoute}
              key={`sb-${item.key}`}
            >
              <SidebarItem key={item.name} active={index === activeIndex}>
                <Tooltip title={menuTitle} isLongTooltip={isQuestionsDisabled}>
                  <IconItem
                    size="24"
                    color={
                      location?.pathname === item?.route
                        ? "#4299E1"
                        : "rgba(0, 0, 0, 0.36)"
                    }
                  />
                </Tooltip>
              </SidebarItem>
            </Link>
          )
        })}
        <FooterContainer>
          {SidebarItems.map((item, index) => {
            if (
              item.protected &&
              get(authUser, ["medicProfile", "userType"]) !== "provider"
            )
              return null
            if (!item.isFooter) return null
            const IconItem = item.icon
            return (
              <Link to={item.route} key={`sb-${item.key}`}>
                <SidebarItem key={item.name} active={index === activeIndex}>
                  <Tooltip title={t(item.lang)}>
                    <IconItem
                      size="24"
                      color={
                        location?.pathname === item?.route
                          ? "#4299E1"
                          : "rgba(0, 0, 0, 0.36)"
                      }
                    />
                  </Tooltip>
                  {item.bubble && (
                    <Bubble>
                      <span>+99</span>
                    </Bubble>
                  )}
                </SidebarItem>
              </Link>
            )
          })}
          {get(authUser, ["medicProfile", "userType"]) === "provider" && (
            <Item isAvatar>
              <Link to="/account">
                <Tooltip title={t("web_client.menu_account")}>
                  <InitialAvatar>
                    {get(authUser, ["medicProfile", "photoURL"], false) !==
                    "local" ? (
                      <img
                        src={
                          get(authUser, ["medicProfile", "photoURL"]) ||
                          defaultPicture
                        }
                        alt="avatar"
                        style={{ width: 40 }}
                      />
                    ) : (
                      <span>
                        {get(
                          authUser,
                          ["displayName", "0"],
                          authUser.email[0].toUpperCase()
                        )}
                      </span>
                    )}
                  </InitialAvatar>
                  <div>
                    <StarRate size="14" />
                    <span>
                      {get(authUser, ["medicProfile", "rating"], "0")}
                    </span>
                  </div>
                </Tooltip>
              </Link>
            </Item>
          )}
          {get(authUser, ["medicProfile", "userType"]) ===
            "medicalAssistant" && (
            <>
              <Item
                isAvatar
                tabIndex={0}
                role="button"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setIsDoctorListVisible(!isDoctorListVisible)
                  }
                }}
                onClick={() => {
                  setIsDoctorListVisible(!isDoctorListVisible)
                }}
              >
                <>
                  <InitialAvatar>
                    {get(authUser, ["medicProfile", "photoURL"], false) !==
                    "local" ? (
                      <img
                        src={
                          get(authUser, ["medicProfile", "photoURL"]) ||
                          defaultPicture
                        }
                        alt="avatar"
                        style={{ width: 40 }}
                      />
                    ) : (
                      <span>
                        {get(
                          authUser,
                          ["displayName", "0"],
                          authUser.email[0].toUpperCase()
                        )}
                      </span>
                    )}
                  </InitialAvatar>
                </>
              </Item>
              <DoctorList
                isModalVisible={isDoctorListVisible}
                handleModalStatus={(status) => {
                  setIsDoctorListVisible(status)
                }}
              />
            </>
          )}
          <Item>
            <Tooltip title={t("web_client.select_language")}>
              <LanguageSelector />
            </Tooltip>
          </Item>
          <SignOutButton
            providerId={get(authUser, "uid")}
            tooltip={t("log_out")}
          />
        </FooterContainer>
      </div>
      <div className="behind-the-scenes" />
    </SidebarParent>
  )
}

Sidebar.propTypes = {
  history: PropTypes.shape().isRequired,
  firebase: PropTypes.shape().isRequired,
}

export default Sidebar

const SwitchTooltipTitle = styled.div`
  padding: 15px;
  width: 100%;
  background: #e53935;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SwitchTooltipContent = styled.div`
  padding: 15px;
  width: 100%;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`

const SwitchTooltipContainer = styled.div`
  position: absolute;
  top: 0;
  left: 80px;
  width: 300px;
`

const SwitchTooltipShadows = styled.div`
  box-shadow: -1px 2px 47px -18px rgba(0, 0, 0, 0.75);
`

const LogoContainer = styled.div`
  padding: 30px 12.5px;
  margin-bottom: 14px;
`

const SidebarParent = styled.div`
  background: #fff;
  border: 1px solid #f2f2f2;
  position: relative;
  width: 71px;
  z-index: 1000;

  a {
    text-decoration: none;
  }

  & > div {
    width: 71px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .behind-the-scenes {
    width: 71px;
  }
`

const FooterContainer = styled.div`
  height: auto;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SidebarItem = styled.div`
  position: relative;
  transition: all 0.25s ease-in-out;
  color: ${(props) =>
    props.active ? props.theme.color.primary : props.theme.color.black.normal};
  margin-bottom: 35px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(:first-child) {
    background: #c34a36;
  }
`

const Bubble = styled.div`
  position: absolute;
  width: 28px;
  height: 24px;
  top: -15px;
  left: 10px;
  background-color: ${({ theme }) => theme.color.error};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px 0px 8px;
  border-radius: 100px;

  & > span {
    font-style: normal;
    font-weight: normal;
    ${(props) => props.theme.font_size.xxxsmall};
    line-height: 1rem;
    /* identical to box height, or 133% */
    text-align: center;
    letter-spacing: 0.025rem;
    color: ${({ theme }) => theme.color.white.normal};
  }
`

export const Item = styled.div`
  margin-bottom: 32px;
  position: relative;
  cursor: ${(props) => (props.isAvatar ? "pointer" : "default")};
  & > a {
    text-decoration: none;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    &:visited {
      color: black;
    }
    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        ${(props) => props.theme.font_size.xsmall};
      }
    }
    svg {
      cursor: pointer;
      color: ${(props) =>
        props.active || props.isAvatar
          ? props.theme.color.secondary
          : props.theme.color.black.regular};
    }
  }
`

export const OnlineLabel = styled.p`
  font-size: 11px;
`
