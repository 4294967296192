import "moment/locale/es"
import moment from "moment"
import { compose } from "recompose"
import React, { useContext } from "react"

// Components
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  HStack,
  Text,
} from "@chakra-ui/react"

// hooks
import { withFirebase } from "../../../utils/Firebase"
import { sessionsActivateUrl } from "../../../utils/App"
import { WithChakra } from "../../../components/atoms/WithChakra"

// utils
import ChatContext from "../../../context/Chat"
import { MODALS, toggleModal } from "../../../context/Chat/reducer"

const ConfirmActivationModal = ({ firebase }) => {
  const { state, dispatch } = useContext(ChatContext)
  const [isActiviting, setIsActiviting] = React.useState(false)
  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={() =>
        dispatch(toggleModal(MODALS.ACTIVATE_SESSION, false, null))
      }
      isOpen={state.modals[MODALS.ACTIVATE_SESSION]}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader backgroundColor="white">Activar la cita.</ModalHeader>
        <ModalCloseButton />
        <ModalBody backgroundColor="white">
          <p>¿Está seguro de que desea activar la cita?</p>
          <span>{`Hora de inicio de la cita: ${moment
            .unix(state?.currentSession?.slotStart)
            .format("HH:mm a")} - Duración: ${
            state?.currentSession?.slotDuration || 0
          } minutos.`}</span>
          <button
            type="button"
            onClick={() =>
              dispatch(toggleModal(MODALS.ACTIVATE_SESSION, false, null))
            }
          />
        </ModalBody>
        <ModalFooter backgroundColor="white">
          <HStack>
            <Button
              disabled={isActiviting}
              onClick={async () => {
                try {
                  setIsActiviting(true)
                  const authToken = await firebase.getIdToken()
                  const activated = await fetch(sessionsActivateUrl, {
                    method: "POST",
                    body: JSON.stringify({
                      key: state?.currentSession?.chatId,
                      aptKey: state?.currentSession?.slotID,
                      providerName: state?.currentSession?.providerDisplayName,
                      clientName: state?.currentSession?.clientDisplayName,
                    }),
                    headers: {
                      Authorization: `Bearer ${authToken}`,
                      "Content-Type": "application/json",
                    },
                  })
                  if (!activated.ok) {
                    setIsActiviting(false)
                    console.log("Error activando la cita")
                    return false
                  }
                  setIsActiviting(false)
                  await dispatch(
                    toggleModal(MODALS.ACTIVATE_SESSION, false, null)
                  )
                  return true
                } catch (error) {
                  setIsActiviting(true)
                  console.error(error)
                  return false
                }
              }}
            >
              <Text>Activar</Text>
            </Button>
            <Button
              onClick={() =>
                dispatch(toggleModal(MODALS.ACTIVATE_SESSION, false, null))
              }
            >
              <Text>Cancelar</Text>
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CompConfirmActivationModal = compose(
  WithChakra,
  withFirebase
)(ConfirmActivationModal)

export default CompConfirmActivationModal
