import React, { useEffect, useRef, useContext, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useToasts } from "react-toast-notifications"
import get from "lodash.get"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Button, Flex, Spinner } from "@chakra-ui/react"

// Components
import MessageBubble from "../../atoms/MessageBubble"

import {
  MessageImage,
  MessageText,
  MessageVideo,
  MessageMedical,
  MessageLocation,
  MessageAudio,
  MessageDocument,
  MessageTip,
  MessageLoader,
} from "../../atoms/MessagesType"
import { TextArea } from "../../atoms/Forms"

// Utils
import { removeMessage } from "../../../context/Chat/reducer"
import { withFirebase } from "../../../utils/Firebase"
import ChatContext from "../../../context/Chat"

import {
  Container,
  ChatMessagesContainer,
  NotesContainer,
  NotesList,
  NoteRow,
} from "./styles"

const getMessageChild = (props) => {
  const { type, ...otherProps } = props
  const types = {
    image: MessageImage,
    text: MessageText,
    system: MessageText,
    video: MessageVideo,
    medical: MessageMedical,
    prescription: MessageMedical,
    clinical: MessageMedical,
    medicalImages: MessageMedical,
    location: MessageLocation,
    audio: MessageAudio,
    document: MessageDocument,
    tip: MessageTip,
    loaderPrescription: MessageLoader,
    loaderClinical: MessageLoader,
    loaderMedicalImages: MessageLoader,
    other: MessageDocument,
  }
  const Component = types[type]
  return <Component currentMessage={otherProps} type={type} />
}

const ChatContainer = ({
  messages,
  user,
  handleClick,
  handleLoadMore,
  session,
  isNotesVisible,
  isDependentChat,
  firebase,
  info,
  handleNotesClick,
}) => {
  const messagesEndRef = useRef(null)
  const messagesStartRef = useRef(null)
  const { t } = useTranslation()
  const { dispatch, state } = useContext(ChatContext)
  const [medicalNotes, setMedicalNotes] = useState([])
  const [note, setNote] = useState("")
  const [currentNote, setCurrentNote] = useState(null)
  const [currentNoteSession, setCurrentNoteSession] = useState(null)
  const [currentContact, setCurrentContact] = useState(null)
  const [sidebarTop, setSidebarTop] = useState(undefined)
  const [isSaving, setIsSaving] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    // messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    messages.forEach((m) => {
      if (
        m.referenceId &&
        state.chats.find((c) => c._id === m.referenceId && c.chatId === session)
      ) {
        dispatch(removeMessage(m.referenceId))
      }
    })
  }, [messages])

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
  }, [state.lastMessageKey])

  useEffect(() => {
    messagesStartRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
    })
  }, [state.firstMessageKey])

  useEffect(() => {
    const toClear = setTimeout(() => {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })

      const chatEl = document
        ?.querySelector(".loadMore")
        ?.getBoundingClientRect()
      setSidebarTop(chatEl?.top)
    }, 1000)
    return () => clearTimeout(toClear)
    // }
  }, [session])

  const isSticky = () => {
    const msgEl = document.querySelector(".loadMore")
    const scrollTop = window.scrollY
    if (scrollTop >= sidebarTop - 10) {
      msgEl.classList.add("is-sticky")
    } else {
      msgEl.classList.remove("is-sticky")
    }
  }

  useEffect(() => {
    if (sidebarTop) {
      window.addEventListener("scroll", isSticky)
    }

    return () => window.removeEventListener("scroll", isSticky)
  }, [sidebarTop])

  useEffect(() => {
    const checkContact = async () => {
      const data = await firebase.checkContact(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        info.clientEmail
      )
      if (data && !data.empty) {
        const contactData = data.docs[0].data()
        setCurrentContact({ id: data.docs[0].id, ...contactData })
      }
    }
    if (user && info) {
      checkContact()
    }
  }, [isNotesVisible])

  useEffect(() => {
    const getNotes = async () => {
      const localNotes = await firebase.getNotes(
        user.isImpersonating ? user.impersonatingEmail : user.email,
        currentContact.id
      )
      if (localNotes) {
        setMedicalNotes(
          localNotes.docs.map((d) => ({
            id: d.id,
            ...d.data(),
          }))
        )
      }
    }
    if ((isNotesVisible && currentContact) || (!isSaving && currentContact)) {
      getNotes()
      setNote("")
    }
  }, [isNotesVisible, currentContact, isSaving])

  const handleAddNote = async () => {
    try {
      setIsSaving(true)
      const currentTime = Date.now()
      let formattedNote = {
        note,
        contact: currentContact.id,
        contactEmail: currentContact.email,
        createdAt: currentTime,
        session,
      }
      if (isDependentChat) {
        const data = await firebase.getDependentProfile(
          currentContact.email,
          isDependentChat.profileOwner
        )
        if (data && data.docs) {
          formattedNote = Object.assign(formattedNote, {
            dependentContact: data.docs[0].id,
            dependentName: isDependentChat.profileOwner,
          })
        }
      }
      await firebase.AddMedicalNotes({
        doctor: user.email,
        contact: currentContact.id,
        medicalNote: formattedNote,
      })
      setIsSaving(false)
    } catch (error) {
      addToast(t("unexpected_error"), {
        appearance: "error",
      })
      setIsSaving(false)
    }
  }

  const handleEditNote = async () => {
    try {
      const noteId = currentNote
      const currentTime = Date.now()
      const formattedNote = {
        note,
        modifiedAt: currentTime,
      }
      await firebase.updateMedicalNote({
        doctor: user.email,
        contact: currentContact.id,
        medicalNote: formattedNote,
        noteId,
      })
      setCurrentNote(null)
      setNote("")
      setMedicalNotes((prev) => [
        ...prev.filter((n) => n.id !== noteId),
        {
          ...prev.find((n) => n.id === noteId),
          ...formattedNote,
        },
      ])
    } catch {
      setIsSaving(false)
      setCurrentNote(null)
    }
  }

  const clearSelectedNote = () => {
    setNote("")
    setCurrentNote(null)
    setCurrentNoteSession(null)
  }

  const hasCurrentNote = medicalNotes.find((n) => get(n, "session") === session)

  const isFaceToFace = useMemo(
    () => get(info, "sessionType") === "faceToFace",
    [info]
  )

  return (
    <Container
      isImpersonating={user.isImpersonating}
      isNotesVisible={isNotesVisible}
    >
      <ChatMessagesContainer isNotesVisible={isNotesVisible}>
        <div style={{ position: "relative" }}>
          <div ref={messagesStartRef} />
          {messages.length === 0 && !isFaceToFace && (
            <MessageBubble
              key="msg-hello"
              type="system"
              handleClick={() => null}
              content={{ text: t("hello_message") }}
            >
              <MessageText
                isBold
                currentMessage={{ text: t("hello_message") }}
                type="system"
              />
            </MessageBubble>
          )}
          {messages.length >= 20 && (
            <div
              className="loadMore"
              key="msg-load-more"
              style={
                isSticky
                  ? {
                      position: "sticky",
                      top: "10px",
                      left: "calc(50% - 100px)",
                      zIndex: 999,
                      animation:
                        "500ms ease-in-out 0s normal none 1 running fadeInDown",
                    }
                  : {}
              }
            >
              <MessageBubble
                handleClick={handleLoadMore}
                type="system"
                content={{ text: t("load_earlier_messages") }}
              >
                <MessageText
                  isLoadMore
                  currentMessage={{ text: t("load_earlier_messages") }}
                  type="system"
                />
              </MessageBubble>
            </div>
          )}
          {[
            ...messages,
            ...state.chats.filter((m) => m.chatId === session),
          ].map((m) => (
            <MessageBubble
              key={`msg-${m._id}`}
              handleClick={handleClick}
              isPatient={m.user ? user.email !== m.user._id : false}
              type={m.type}
              content={m}
            >
              {getMessageChild(m)}
            </MessageBubble>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </ChatMessagesContainer>
      {isNotesVisible && (
        <NotesContainer>
          <NotesList>
            {medicalNotes &&
              medicalNotes.length > 0 &&
              medicalNotes
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((n) => (
                  <NoteRow
                    isCurrentNoteSession={get(n, "session") === session}
                    isActive={n.id === currentNote}
                    key={n.id}
                    onClick={() => {
                      if (currentNote) {
                        if (n.id === currentNote) {
                          setNote("")
                          setCurrentNoteSession(null)
                          setCurrentNote(null)
                        } else {
                          setNote(n.note)
                          setCurrentNoteSession(n.session)
                          setCurrentNote(n.id)
                        }
                      } else {
                        setNote(n.note)
                        setCurrentNoteSession(n.session)
                        setCurrentNote(n.id)
                      }
                    }}
                  >
                    <span>
                      {moment(n.createdAt)
                        .format("MMM DD, YYYY HH:mm:ss")
                        .toUpperCase()}
                    </span>
                    <span>
                      {n.dependentContact ? n.dependentName : "Encargado"}
                    </span>
                  </NoteRow>
                ))}
          </NotesList>
          <TextArea
            key="new-note"
            mt="10px"
            mb="0px"
            height="30vh"
            value={note}
            onChange={(e) => {
              const { value } = e.target
              setNote(value)
            }}
            placeholder={
              hasCurrentNote
                ? t("web_client.add_update_to_notes")
                : `${t("web_client.add_notes")} ${t("web_client.patient")}`
            }
            id="note"
            name="note"
            disabled={
              (!hasCurrentNote && currentNote) ||
              (Boolean(hasCurrentNote) && currentNoteSession !== session) ||
              (Boolean(hasCurrentNote) && Boolean(currentNoteSession) === false)
            }
          />
          <Flex height="8vh" justifyContent="flex-end" alignItems="center">
            <Button
              width="100px"
              borderRadius="5px"
              p="5px"
              border="1px solid rgba(226, 232, 240, 1)"
              mx="5px"
              onClick={currentNote ? clearSelectedNote : handleNotesClick}
              minH="40px"
            >
              {t("cancel")}
            </Button>
            <Button
              width="100px"
              borderRadius="5px"
              p="5px"
              border="1px solid rgba(226, 232, 240, 1)"
              mx="5px"
              onClick={() =>
                currentNoteSession === session
                  ? handleEditNote()
                  : handleAddNote()
              }
              minH="40px"
              disabled={
                (hasCurrentNote && currentNoteSession !== session) ||
                (!hasCurrentNote && currentNote)
              }
            >
              {isSaving ? <Spinner size="sm" /> : t("save")}
            </Button>
          </Flex>
        </NotesContainer>
      )}
    </Container>
  )
}

ChatContainer.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.shape().isRequired,
}

export default withFirebase(ChatContainer)
